import React from "react";
import styled, { css } from "styled-components"
import potPreviewImage from "../pot-preview-image.png"
import {SimpleCard} from "./SimpleCard"

const PotPreviewStyled = styled.div`
  position: relative;
  max-width: 400px;
  font-size: 0;

  @media (max-width: 680px) {
    max-width: none;
  }
`

const PreviewBackground = styled.img`
  position: relative;
  width: 100%;
`

const PreviewOverlay = styled.div`
  position: absolute;
  top: 36%;
  left: 14.6%;
  width: 70.7%;
  border-radius: 3.4%/5.4%;
`

export const PotPreview = ({src}) => (
  <PotPreviewStyled>
    <PreviewBackground src={potPreviewImage} />
    <PreviewOverlay>
      <SimpleCard src={src} style={{boxShadow: "none"}}/>
    </PreviewOverlay>
  </PotPreviewStyled>
)

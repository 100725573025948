import React from "react"
import styled, { css } from "styled-components"

const CardImageWrapper = styled.div`
  height:0;
  width: 100%;
  padding-top: calc(336 / 530 * 100%);
  background-size: cover;
  background-position: center center;

  ${props => css`
    background-image: url(${props.backgroundUrl});
  `}
`

const CardImage = styled.img`
  margin-top: -100%;
  width: 100%
  opacity: 0;
`

const UnstyledSimpleCard = ({className, style, src}) => (
  <div className={className} style={style}>
    <CardImageWrapper backgroundUrl={src}>
      <CardImage src={src} />
    </CardImageWrapper>
  </div>
)

export const SimpleCard = styled(UnstyledSimpleCard)`
  border-radius: 4px;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
  overflow: hidden;
`

import React from "react"
import _ from "lodash"
import { Link } from "react-router-dom";
import {PotImageCard} from "../components/PotImageCard"
import fakeApiData from "../fake-api-data"
import styled from "styled-components"
import {TagList} from "../components/TagList"

const CardGallery = styled.div`
display: flex;
flex-flow: row wrap;
justify-content: center;
`

let potData = fakeApiData["pot-images"];

const Hero = styled.div``

// TODO: Use reduce (fine for hack purposes)
const maxTags = 15
const popularTags = _.shuffle(_.map(_.take(_.reverse(_.sortBy(_.map(_.groupBy(_.flatMap(potData, 'tags'), _.identity), (v, k) => [k, _.size(v)]), (v) => v[1])), maxTags), _.first))

export const Index = (props) => {
  const { match: { params: { tag, person} } } = props;

  let filteredPotData = potData;

  filteredPotData = _.sortBy(filteredPotData, 'id')

  if (tag) {
    filteredPotData = _.filter(filteredPotData, (pd) => _.includes(pd.tags, tag));
  }

  if (person) {
    filteredPotData = _.filter(filteredPotData, (pd) => pd.credit_name = person)
  }

  return (
    <div>
      <Hero>
        <h4 className="subtitle has-text-centered" style={{marginTop: 10}}>
          Get that perfect image for your Monzo pot.
        </h4>
      </Hero>


      {
        tag
          ? null
          : (
            <div style={{margin: 20, marginBottom: 0, display: "flex", flexDirection: "column", alignItems: "center"}}>
              <div style={{textAlign: "center", textDecoration: "underline"}}>Popular tags</div>
              <TagList tags={popularTags} style={{flexFlow: "row wrap"}} />
            </div>
          )
      }

      { tag
          ? (
            <div style={{textAlign: "center", marginTop: 10}}>
              <p><strong>#{tag}</strong> images — <Link to={"/"}>view all</Link></p>
            </div>
          )
          : null
      }
      <CardGallery>
        {filteredPotData.map((d, i) => <PotImageCard index={i} {...d} />)}
      </CardGallery>
    </div>
  )
}

import React from 'react';

import 'bulma/css/bulma.css'

import styled, { css } from "styled-components"

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Navbar } from './Navbar.js'
import { Index} from './routes/Index.js'
import { New } from './routes/New.js'
import { Show } from './routes/Show.js'
import logo from "./pottery-logo.png"

import './App.css';

const Logo = styled.img`
  height: 40px;
`

const Nav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const NavBrand = styled.div``

const App = ({className}) => (
  <Router>
    <div className={className}>
      <Nav>
        <NavBrand>
          <Link to="/" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Logo src={logo} />
            <span style={{fontSize: 20, fontWeight: 600,marginLeft: 10, color: "#444"}}>Pottery</span>
          </Link>
        </NavBrand>
        <div>
          <Link className="button is-success" to="/new">Create</Link>
        </div>
      </Nav>

      <Route path="/" exact component={Index} />
      <Route path="/new" exact component={New} />
      <Route path="/pots/tags/:tag" exact component={Index} />
      <Route path="/pots/people/:person" exact component={Index} />
      <Route path="/pots/:potImageID" exact component={Show} />

      <div style={{marginTop: 40, marginBottom: 20, textAlign: "center", fontSize: "0.8em"}}>
        Hacked together with ❤️&amp; 🍺 by { " " } <a href="https://github.com/petehamilton">Pete Hamilton</a>
        <br/>
        Have questions, ideas or feedback? <a href="mailto:pete@hamilton.dev">Email me</a>
        <br/>
        —
        <br/>
        Otherwise, please note this is just a fun side project, not endorsed by <a href="https://monzo.com">Monzo</a>. All logos, trademarks and copyright remain theirs, obviously.
  </div>
</div>
    </Router>
    );

    const StyledApp = styled(App)`
    margin: 40px;

    @media (max-width: 480px) {
      margin: 20px;
    }
    `

    export default StyledApp;

import React from "react"
import _ from "lodash"
import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components"

const Tag = styled(Link)`
  margin: 0 5px;
  color: #999;
  white-space: nowrap;
  &:hover {
    color #444;
  }
`

const UnstyledTagList = ({className, style, tags}) => (
  <div className={className} style={style}>
    { _.map(tags, (tag) => <Tag to={`/pots/tags/${tag}`}>#{tag}</Tag>) }
  </div>
)

export const TagList = styled(UnstyledTagList)`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
`

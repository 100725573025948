import React from "react"
import { Link } from "react-router-dom";
import styled, { css } from "styled-components"

const NavbarContainer = styled.nav`
    background-color: white;
    min-height: 3.25rem;
    position: relative;
    z-index: 30;
`

const NavbarMenu = styled.div`
  background-color: white;
  box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
  padding: 0.5rem 0;
`

export const Navbar = () => (
  <NavbarContainer>
    <NavbarMenu>
      <div className="navbar-item">
        <div className="buttons">
          <Link to="/new" className="button is-success">
            Create
          </Link>
        </div>
      </div>
    </NavbarMenu>
  </NavbarContainer>
)

import React from "react"
import _ from "lodash"
import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components"
import { TagList } from "./TagList"

const popin = keyframes`
  0% { transform: scale(0.95) }
  100% { transform: scale(1) }
`;

const fadein = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

// Note: Monzo cards are 336:530
const CardWrapper = styled.div`
  opacity: 0;
  transform: scale(0.95);
  animation: ${popin} 0.4s ease-in forwards,
             ${fadein} 0.3s ease-in forwards;

  ${props => css`
    animation-delay: ${100 * props.index}ms;
  `}

  margin: 20px;
  display: flex;
  flex-direction: column;

  width: calc(23% - 20px);

  @media (max-width: 1080px) {
    width: calc(30% - 20px);
  }

  @media (max-width: 780px) {
    width: calc(50% - 40px);
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`

const Card = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
  overflow: hidden;
  cursor: zoom-in;
`

const CardImageWrapper = styled.div`
  height:0;
  width: 100%;
  padding-top: calc(336 / 530 * 100%);
  background-size: cover;
  background-position: center center;

  ${props => css`
    background-image: url(${props.backgroundUrl});
  `}
`

const CardImage = styled.img`
  width: 100%
`

const CardInner = styled.div`
  position absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  transition: background-color 0.2s ease-in-out;

  ${Card}:hover & {
    background-color: rgba(0,0,0,0.4);
  }
`

const CardInfo = styled.div`
  height: 60px;
  padding: 10px 15px;
  display: flex;
  opacity: 0;
  flex-direction: row;
  background: rgba(0,0,0,0.5);
  color: #FFF;
  align-items: center;
  transition: opacity 0.2s ease-in-out;

  ${Card}:hover & {
    opacity: 1;
  }
`

const Avatar = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  overflow: hidden;
  border: 2px solid rgba(255,255,255,0.5);
`

const UploaderName = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  font-weight: 600;
  color: #FFF;
  &:hover {
    color: #FFF;
  }
`

const CardLikes = styled.a`
  margin: 15px 15px 0px 0px;
  padding: 6px 8px;
  flex: none;
  display: none;
  align-self: flex-end;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: rgba(238,238,238,0.8);
  color: #444;
  cursor: pointer;
  line-height: 1em;
  display: flex;

  &:hover {
    background: rgba(238,238,238,1);
  }
`

const CardLikesHeart = styled.span`
  color: rgb(240,80,80);
  font-size: 15px;
  flex: none;
  margin-right: 6px;
`

const CardLikesHeartCount = styled.span`
  flex: 1;
  font-weight: 600;
`

export const PotImageCard = ({
  id,
  image_url,
  credit_url,
  credit_name,
  avatar_url,
  like_count,
  liked_by_user,
  tags,
  index,
}) => (
  <CardWrapper index={index}>
    <Card to={`/pots/${id}`} id={`thing-${id}`}>
      <CardImageWrapper backgroundUrl={image_url}>
      </CardImageWrapper>
      <CardInner>
        <div></div>

        <CardInfo>
          <Avatar>
            <img src={avatar_url} style={{width: "100%", height: "100%"}}/>
          </Avatar>

          <div style={{flex: 1, display: "flex"}}>
            <UploaderName href={credit_url}>{credit_name}</UploaderName>
          </div>
        </CardInfo>
      </CardInner>
    </Card>

    <TagList tags={tags} style={{justifyContent:"flex-end"}}/>
  </CardWrapper>
)

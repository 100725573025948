import React, {Component} from 'react';
import { Link } from "react-router-dom";

import _ from "lodash"
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'; // see installation section above for versions of NPM older than 3.0.0
import styled, { css } from "styled-components"
import {PotPreview} from "../components/PotPreview"
import {SimpleCard} from "../components/SimpleCard"

class CropWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cropResult: null,
    };
    this.cropImage = this.cropImage.bind(this);
  }

  cropImage() {
    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }

    const cropResult = this.cropper.getCroppedCanvas().toDataURL();
    this.props.onCrop(cropResult)
  }

  render() {
    // Warning: HAX
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

    return (
        <Cropper
          style={{maxWidth: 350, width: 350, height: 300, margin: "0 auto"}}
          ref='cropper'
          aspectRatio={530/336}
          responsive={true}
          guides={false}
          viewMode={1}
          autoCropArea={1}
          cropBoxMovable={false}
          cropBoxResizable={false}
          dragMode={"move"}
          src={this.props.src}
          ref={cropper => { this.cropper = cropper; }}
          crop={_.debounce(this.cropImage, 500)}
        />
    )
  }
}


export class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.props.onFileUploaded(reader.result)
    };
    reader.readAsDataURL(files[0]);
  }

  render() {
    return (
      <div style={{display: "flex", flexDirection: "column", alignItems:"center", marginTop: 40}}>
        <div className="file is-boxed">
          <label className="file-label">
            <input className="file-input" type="file" onChange={this.onChange}/>
            <span className="file-cta">
              <span className="file-icon">
                <i className="fas fa-upload"></i>
              </span>
              <span className="file-label">
                Choose a file…
              </span>
            </span>
          </label>
        </div>
      </div>
    )
  }
}

export class New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      happy: null,
      fileData: null,
      cropResult: null,
    };
    this.onCrop = this.onCrop.bind(this);
    this.downloadPotImage = this.downloadPotImage.bind(this);
    this.downloadURI = this.downloadURI.bind(this);
  }

  onFileUploaded(fileData) {
    this.setState({fileData})
  }

  onCrop(cropResult) {
    this.setState({cropResult})
  }

  downloadURI(uri) {
    let link = document.createElement("a");
    link.download = "pot.png";
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    //delete link;
  }

  downloadPotImage() {
    const img = new Image()

    const dluri = this.downloadURI

    // When the images is loaded, resize it in canvas.
    img.onload = function(){
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const scaleFactor = 2

      const imageHeight = 336 * scaleFactor
      const imageWidth = 530 * scaleFactor

      canvas.width = imageWidth;
      // 336 + 25% top and bottom
      canvas.height= imageHeight * 1.5;

      // draw the img into canvas
      ctx.drawImage(this, 0, imageHeight*0.25, imageWidth, imageHeight);

      // Extend top
      const f = () => {
        const pixelData = canvas.getContext('2d').getImageData(0, imageHeight*0.25, imageWidth, 1).data;
        const pixels = _.chunk(pixelData, 4)
        const rgbas = _.map(pixels, (pixel) => `rgba(${_.join(pixel, ",")})`)
        if (_.size(_.uniq(rgbas)) == 1) {
          // All the top row is the same colour, colour in above
          const pixel = pixels[0];

          ctx.globalCompositeOperation='destination-over';
          ctx.rect(0, 0, imageWidth, imageHeight*0.25 + 5);
          ctx.fillStyle=`rgba(${_.join(pixel, ",")})`
          ctx.fill();
          // All the top row is the same colour, colour in above
        }
      }
      f()

      // Extend bottom
      const g = () => {
        const pixelData = canvas.getContext('2d').getImageData(0, imageHeight*1.25-1, imageWidth, 1).data;
        const pixels = _.chunk(pixelData, 4)
        const rgbas = _.map(pixels, (pixel) => `rgba(${_.join(pixel, ",")})`)
        if (_.size(_.uniq(rgbas)) == 1) {
          // All the top row is the same colour, colour in above
          const pixel = pixels[0];

          ctx.globalCompositeOperation='destination-over';
          ctx.rect(0, imageHeight*1.25-5, imageWidth, imageHeight*1.5 + 5);
          ctx.fillStyle=`rgba(${_.join(pixel, ",")})`
          ctx.fill();
          // All the top row is the same colour, colour in above
        }
      }

      g()

      // Run the callback on what to do with the canvas element.
      const output = canvas.toDataURL('image/png')
      dluri(output)
    };

    img.src = this.state.cropResult;
  }

  render() {
    if (this.state.fileData && this.state.happy) {
      return (
        <div style={{maxWidth:640, margin: "0 auto"}}>
          <div style={{margin: 40, marginBottom: 20}}>
            <SimpleCard src={this.state.cropResult} />
          </div>

          <div style={{margin: 40, marginTop: 20 }}>
            <p style={{marginBottom: 10}}>
              Save the image above to use it in Monzo!
            </p>
            <p style={{marginBottom: 10}}>
              Usually if you "tap and hold" you're given the option to save.
            </p>
            <p style={{marginBottom: 20}}>
              Alternatively, you can attempt to download using this button but it's a little temperamental...
            </p>
            <div className="button is-outline is-success is-fullwidth" onClick={this.downloadPotImage}>
              <span className="icon">
                <i className="far fa-arrow-alt-circle-down"></i>
              </span>
              <span>Download</span>
            </div>
          </div>

          <div style={{display: "flex", justifyContent: "center", marginBottom: 20}}>
            <div className="button is-outline" onClick={() => this.setState({fileData: null, cropResult: null, happy: null})}>
              <span className="icon">
                <i className="fas fa-redo"></i>
              </span>
              <span>Go again</span>
            </div>
          </div>
        </div>
      );
    } else if (this.state.fileData) {
      return (
        <div style={{maxWidth:640, margin: "0 auto"}}>
          <CropWidget src={this.state.fileData} onCrop={(cropResult) => this.setState({cropResult})}/>
          <div style={{padding: 20, display: "flex", justifyContent: "space-between"}}>
            <div className="button" onClick={() => this.setState({cropResult: null, fileData: null, happy: false})}>Back</div>
            <div className="button is-success" onClick={() => this.setState({happy: true})}>Ok, all good</div>
          </div>
          <div className="panel" style={{margin: 20, marginTop: 0}}>
            <p className="panel-heading has-text-centered">
              App Preview
            </p>
            <div className="panel-block is-paddingless" style={{justifyContent: "center"}}>
              <PotPreview src={this.state.cropResult}/>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div style={{maxWidth:640, margin: "0 auto"}}>
          <FileUpload onFileUploaded={(fileData) => this.setState({fileData})} />
        </div>
      )
    }
  }
}

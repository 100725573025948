import React from "react"
import _ from "lodash"
import styled, { css } from "styled-components"
import fakeApiData from "../fake-api-data"
import {PotPreview} from "../components/PotPreview"
import {SimpleCard} from "../components/SimpleCard"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 40px;
  justify-content: center;

  @media (max-width: 680px) {
    flex-direction: column;
    margin: 20px;
  }
`

const PotImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  max-width: 400px;

  @media (max-width: 680px) {
    margin-right: 0;
    max-width: none;
  }
`

const ExtraContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;

  @media (max-width: 680px) {
    margin-right: 0;
  }
`

const UnstyledCredits = ({className, credit_name, credit_url, credit_type}) => (
  <div className={className}>
    <div className="panel">
      <p className="panel-heading has-text-centered">
        Attribution
      </p>
      <div className="panel-block">
        {
          credit_type == "unsplash"
            ? (
              <p>Credit and copyright for this image belongs to {" "} <a href={credit_url}>{credit_name}</a> {" "} on {" "} <a href="https://unsplash.com">Unsplash</a>.</p>
            )
            : (
              <p>Credit and copyright for this image belongs to {" "} <a href={credit_url}>{credit_name}</a> {" "} from the Monzo Community forums.</p>
            )
            }
      </div>
    </div>
  </div>
)

const Credits = styled(UnstyledCredits)`
  margin-bottom: 20px;
`

const DownloadButtons = styled.div`
  display: flex;
  flexDirection: row;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const Show = ({ match: { params: { potImageID } } }) => {
  const potImage = _.find(fakeApiData["pot-images"], { id: potImageID });
  console.debug('===============> potImage', potImage); // TODO (Pete): REMOVE ME

  return (
    <Container>
      <PotImageContainer>
        <SimpleCard src={potImage.image_url}/>
        <DownloadButtons>
          <a className="button is-outline is-success is-fullwidth" href={potImage.image_url} download>
            <span className="icon">
              <i className="far fa-arrow-alt-circle-down"></i>
            </span>
            <span>Download</span>
          </a>
        </DownloadButtons>

        <Credits credit_name={potImage.credit_name} credit_url={potImage.credit_url} credit_type={potImage.credit_type}/>
      </PotImageContainer>

      <ExtraContainer>
        <div className="panel">
          <p className="panel-heading has-text-centered">
            Preview
          </p>
          <div className="panel-block is-paddingless">
            <PotPreview src={potImage.image_url} />
          </div>
        </div>
      </ExtraContainer>
    </Container>
  )
}
